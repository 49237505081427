<template>
  <div>
    <b-modal
      ref="modalDataCriteriosInmovilla"
      :title="$t('Critérios de aplicabilidade Inmovilla')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p> {{ $t('O imóvel tem de estar no estado ativo.') }} </p>

      <p> {{ $t('O tipo de negócio definido deve estar de acordo ao tipo de negócio definido pelo seu HUB.') }} </p>

      <p v-if="tiposNegocio === ''">
        <strong> {{ $t('Não existem tipos de negócio definidos no seu HUB.') }} </strong>
      </p>

      <p v-if="tiposNegocio !== ''">
        {{ $t('Os tipos de negócio definidos no seu HUB são:') }} <strong> {{ tiposNegocio }} </strong>
      </p>

      <p> {{ $t('O tipo de imóvel definido deve estar de acordo ao tipo de imóvel definido pelo seu HUB.') }} </p>

      <p v-if="tiposImovel === ''">
        <strong> {{ $t('Não existem tipos de imóvel definidos no seu HUB.') }} </strong>
      </p>

      <p v-if="tiposImovel !== ''">
        {{ $t('Os tipos de imóvel definidos no seu HUB são:') }} <strong> {{ tiposImovel }} </strong>
      </p>

      <p> {{ $t('O preço tem de estar definido.') }} </p>

      <p> {{ $t('A zona tem de estar definida.') }} </p>

      <p> {{ $t('O municipio de estar definida.') }} </p>

      <p> {{ $t('Uma das seguintes áreas tem de estar definida: área útil, área bruta ou área do terreno.') }} </p>

      <p> {{ $t('Qualquer alteração de dados no imóvel só será atualizado no portal na próxima rotina.') }} </p>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    tiposImovel: {
      type: String,
      default: () => '',
    },
    tiposNegocio: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCriteriosInmovilla.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataCriteriosInmovilla.hide()
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
